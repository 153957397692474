import React, { useState, useContext, useEffect } from "react";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import DrawerContext from "../../../context/DrawerContext";
import "./TopicsDrawer.scss";

const EditTopicsDrawer = () => {
  const drawerCtx = useContext(DrawerContext);
  const isOpen = drawerCtx.details?.type === "edit-topic";

  console.log("Drawer Opened with Details:", drawerCtx.details);

  const [topicName, setTopicName] = useState("");
  const [isCreating, setCreating] = useState(false);

  useEffect(() => {
    if (isOpen && drawerCtx.details?.title) {
      console.log("Setting Topic Name:", drawerCtx.details.title);
      setTopicName(drawerCtx.details.title);
    }
  }, [isOpen, drawerCtx.details]);

  const handleSave = () => {
    if (!topicName.trim()) return;
  
    setCreating(true);
  
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/topic/${drawerCtx.details.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ title: topicName }),
    })
      .then((res) => res.ok ? res.json() : Promise.reject(`Error ${res.status}`))
      .then((data) => data.status === "success" && drawerCtx.closeDrawer())
      .catch(console.error)
      .finally(() => setCreating(false));
  };  

  if (!isOpen) return null;

  return (
    <div className={`topic-drawer ${isOpen ? "open" : ""}`}>
      <div className="drawer-header">
        <Typography variant="h6">Edit Topic</Typography>
      </div>
      <div className="drawer-content">
        <TextField
          className="full-width-column"
          fullWidth
          label="Topic Name"
          value={topicName}
          onChange={(e) => setTopicName(e.target.value)}
        />
      </div>
      <div className="drawer-footer">
        <Button variant="contained" className="btn-primary cancel-button" onClick={drawerCtx.closeDrawer}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={!topicName.trim() || isCreating} className="btn-primary save-button">
          {isCreating ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default EditTopicsDrawer;
