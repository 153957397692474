import React, { useState, useContext } from "react";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import DrawerContext from "../../../context/DrawerContext";
import "./TagsDrawer.scss";

const AddTagsDrawer = () => {
  const drawerCtx = useContext(DrawerContext);
  const isOpen = drawerCtx.details?.type === "create-tag";

  const [tagName, setTagName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const isCreateDisabled = tagName.trim() === "";

  if (!isOpen) return null;

  const handleSave = () => {
    if (isCreateDisabled) return;
    setIsCreating(true);
    const payload = { title: tagName }; 
    console.log("Saving Tag:", payload);
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/tag`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then(async (res) => {
        if (!res.ok) {
          const errorMessage = await res.text();
          throw new Error(`Error ${res.status}: ${errorMessage}`);
        }
        return res.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log("Tag added successfully:", data);
          if (typeof drawerCtx.details?.onUpdate === "function") {
            drawerCtx.details.onUpdate((prevTags) => [{ id: data.tag?.id || Date.now(), title: tagName }, ...prevTags]);
          }
          drawerCtx.closeDrawer();
        }
      })
      .catch((error) => console.error("Tag creation failed:", error))
      .finally(() => setIsCreating(false));
  };

  return (
    <div className={`tags-drawer ${isOpen ? "open" : ""}`}>
      <div className="drawer-header">
        <Typography variant="h6">Add Tag</Typography>
      </div>
      <div className="drawer-content">
        <TextField className="full-width-column" fullWidth label="Tag Name" value={tagName} onChange={(e) => setTagName(e.target.value)}/>
      </div>
      <div className="manage-buttons">
        <Button variant="contained" className="cancel-button" onClick={drawerCtx.closeDrawer}>
          Cancel
        </Button>
        <Button variant="contained" className="btn-primary save-button" onClick={handleSave} disabled={isCreateDisabled}>
          {isCreating ? <CircularProgress size={24} style={{ color: "white" }} /> : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default AddTagsDrawer;
