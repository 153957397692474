import React, { useState, useContext, useMemo } from "react";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import DrawerContext from "../../../context/DrawerContext";
import "./TagsDrawer.scss";

const EditTagsDrawer = () => {
  const drawerCtx = useContext(DrawerContext);
  const isOpen = drawerCtx.details?.type === "edit-tag";

  console.log("Full Drawer Context:", drawerCtx.details); // Debugging

  // Use 'title' instead of 'tag' since the context contains 'title'
  const selectedTag = useMemo(() => drawerCtx.details?.title || "", [drawerCtx.details]);

  const [tagName, setTagName] = useState(selectedTag);
  const [isCreating, setIsCreating] = useState(false);
  const isCreateDisabled = tagName.trim() === "";

  if (!isOpen) return null;

  const handleSave = () => {
    if (!drawerCtx.details?.id) {
      console.error("No tag ID found in context.");
      return;
    }
    setIsCreating(true);
    const payload = { title: tagName, };
    console.log("Sending Payload:", payload);
    return fetch(`${process.env.REACT_APP_API_URI}/v3/admin/tag/${drawerCtx.details.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            console.error("Error Response:", text);
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          console.log("Tag updated successfully:", data);
          drawerCtx.closeDrawer();
        }
      })
      .finally(() => setIsCreating(false));
  };    

  return (
    <div className={`tags-drawer ${isOpen ? "open" : ""}`}>
      <div className="drawer-header">
        <Typography variant="h6">Edit Tag</Typography>
      </div>
      <div className="drawer-content">
        <TextField className="full-width-column" fullWidth label="Tag Name" value={tagName} onChange={(e) => setTagName(e.target.value)} />
      </div>
      <div className="manage-buttons">
        <Button variant="contained" className="cancel-button" onClick={drawerCtx.closeDrawer}>
          Cancel
        </Button>
        <Button variant="contained" className="btn-primary save-button" onClick={handleSave} disabled={isCreateDisabled}>
          {isCreating ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default EditTagsDrawer;
