import React, { useEffect, useState, useContext } from "react";
import { 
  Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, IconButton, CircularProgress, TextField, InputAdornment, TablePagination 
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import ModalContext from "../../context/ModalContext.js";
import DrawerContext from "../../context/DrawerContext.js";
import AuthContext from "../../context/AuthContext.js";
import "../../Pages/Tags/Tags.scss";

const Tags = () => {
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => { getAllTags(); }, []);
  useEffect(() => { getAllTags(); }, [drawerCtx, modalCtx]);

  const getAllTags = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/tags", { method: "GET", headers: { "Content-Type": "application/json" } })
      .then((response) => response.json())
      .then((data) => {
        setTags(data.status === "success" && Array.isArray(data.data) ? data.data : []);
        setLoading(false);
      })
      .catch(() => {
        setTags([]);
        setLoading(false);
      });
  };

  const openDeleteTagModal = (id) => {
    modalCtx.setDetails("delete-tag", { id });
    modalCtx.openModal();
  };

  const toggleDrawerHandler = (drawer, data = {}) => {
    drawerCtx.setDetails(drawer, { type: drawer, ...data }); 
    drawerCtx.openDrawer();
  };

  const handleChangePage = (event, newPage) => { setPage(newPage); };
  const handleChangeRowsPerPage = (event) => { 
    setRowsPerPage(Math.max(10, parseInt(event.target.value, 10))); 
    setPage(0); 
  };

  return (
    <div className="tags-container">
      <div className="header-section">
        <span className="title">TAGS</span>
        <div className="header-items-div">
          <TextField variant="outlined" size="small" placeholder="Search" value={searchInput} 
            onChange={(e) => setSearchInput(e.target.value)} 
            InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }} 
            className="search-bar" />
          <Button variant="contained" className="add-button" onClick={() => toggleDrawerHandler("create-tag")}>+ ADD TAG</Button>
        </div>
      </div>
      <section className="tags-table-container">
        <TableContainer component={Paper} className="tags-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tags-head-cell">Tag Name</TableCell>
                <TableCell className="tags-head-cell" align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center" style={{ border: "0px" }}><CircularProgress size={48} style={{ color: "#e84118",border: "0px" }} /></TableCell>
                </TableRow>
              ) : tags.length > 0 ? (
                <>
                  {tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tag) => (
                    <TableRow key={tag.id} className="tags-table-row">
                      <TableCell className="tags-table-cell">{tag.name || tag.title || "Unnamed Tag"}</TableCell>
                      <TableCell className="tags-table-cell" align="right">
                        <div className="action-buttons">
                          <IconButton className="action-icon" size="small" onClick={() => toggleDrawerHandler("edit-tag", tag)}>
                            <Edit fontSize="small" />
                          </IconButton>
                          <IconButton className="action-icon" size="small" onClick={() => openDeleteTagModal(tag.id)}>
                            <Delete fontSize="small" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">No Tags Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      <TableContainer component={Paper} className="tags-pagination">
        <Table>
          <TableBody>
            <TableRow>
              <TablePagination 
                rowsPerPageOptions={[10, 25, 50]} 
                colSpan={3} 
                count={tags.length} 
                rowsPerPage={rowsPerPage} 
                page={page}
                onPageChange={handleChangePage} 
                onRowsPerPageChange={handleChangeRowsPerPage} 
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Tags;
