import { useContext } from "react";
import { Button } from "@mui/material";
import AuthContext from "../../../context/AuthContext";
import ModalContext from "../../../context/ModalContext";

const DeleteTopicModal = ({ modalDetails }) => {
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => modalCtx.closeModal();

  const deleteTopic = () => {
    if (!modalDetails?.id) return;

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/topic/${modalDetails.id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${authCtx.token}` },
    })
      .then((res) => res.ok ? res.json() : Promise.reject(`Error ${res.status}`))
      .then((data) => data.status === "success" && modalCtx.closeModal())
      .catch(console.error);
  };

  return (
    <div className="create-devotional-container">
      <div className="devotional-header"><h1>Delete Topic</h1></div>
      <div className="body-container create-container">
        <span>Are you sure you want to delete this Topic?</span>
      </div>
      <div className="footer-devotional">
        <Button style={{ color: "#555" }} onClick={handleCloseModal}>No</Button>
        <Button style={{ color: "#555" }} onClick={deleteTopic}>Yes</Button>
      </div>
    </div>
  );
};

export default DeleteTopicModal;
