import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import DrawerContext from "../../../context/DrawerContext";
import AuthContext from "../../../context/AuthContext";

const ManageLocationsDrawer = ({ drawerDetails }) => {
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);
  const erServiceId = drawerDetails?.selectedER?.id;
  const erServiceLocations = drawerDetails?.selectedER?.locations;
  const companyId = drawerDetails?.selectedER?.companyId || drawerDetails?.selectedER?.company?.id;
  const authToken = authCtx?.token;

  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (!companyId || !authToken) return;

    setIsLoading(true);

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/${companyId}/locations`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        const locationList = Array.isArray(data.data) ? data.data : [];
        setLocations(locationList);
        const preselectedLocations = locationList.filter((loc) => erServiceLocations.some((obj) => obj.locationId === loc.id)).map((loc) => loc.id);
        setSelectedLocations(preselectedLocations);
        setSelectAll(preselectedLocations.length === locationList.length);
        // setLocations(Array.isArray(data.data) ? data.data : []);
        // setSelectedLocations(
        //   data.data
        //     .filter((loc) => erServiceLocations.some((obj) => obj.locationId === loc.id))
        //     .map((loc) => loc.id)
        // );
      })
      .catch((error) => console.error("Error fetching locations:", error))
      .finally(() => setIsLoading(false));
  }, [companyId, authToken]);

  const handleCheckboxChange = (locationId) => {
    setSelectedLocations((prevSelected) => {
      const newSelected = prevSelected.includes(locationId)
        ? prevSelected.filter((id) => id !== locationId)        
        : [...prevSelected, locationId];
      setSelectAll(newSelected.length === locations.length);
      return newSelected;
    });
  };

  // const handleCheckboxChange = (locationId) => {
  //   setSelectedLocations((prevSelected) =>
  //     prevSelected.includes(locationId)
  //       ? prevSelected.filter((id) => id !== locationId)
  //       : [...prevSelected, locationId]
  //   );
  // };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedLocations([]);
    } else {
      setSelectedLocations(locations.map((loc) => loc.id));
    }
    setSelectAll(!selectAll);
  };

  const isSaveDisabled = () => selectedLocations.length === 0 || isCreating;

  const editEmergencyServiceLocation = () => {
    if (!selectedLocations || selectedLocations.length === 0) {
      console.error("Error: No locations selected.");
      return;
    }
    setIsCreating(true);
    const payload = { erServiceId, locationIds: selectedLocations };

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services/${companyId}/locations`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "success") {
          throw new Error(data.message || "Failed to save locations");
        }
        drawerCtx.setPageUpdate({ page: "ERServices" });
        drawerCtx.closeDrawer();
      })
      .catch((error) => console.error("Error saving locations:", error))
      .finally(() => setIsCreating(false));
  };

  const getGoogleMapsUrl = (latitude, longitude) => `https://www.google.com/maps/@${latitude},${longitude},9z`;

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1>Edit Emergency Service Locations</h1>
        </header>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress size={34} />
          </div>
        ) : (
          <TableContainer className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="erservices-table-cell"><input type="checkbox" checked={selectAll}onChange={handleSelectAllChange}/> </TableCell>
                  <TableCell className="erservices-table-cell">Title</TableCell>
                  <TableCell className="erservices-table-cell">Address</TableCell>
                  <TableCell className="erservices-table-cell">Latitude</TableCell>
                  <TableCell className="erservices-table-cell">Longitude</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="erservices-table-body">
                {locations.length ? (
                  locations.map((location) => (
                    <TableRow key={location.id} className="erservices-table-row">
                      <TableCell className="erservices-table-cell">
                        <input
                          type="checkbox"
                          checked={selectedLocations.includes(location.id)}
                          onChange={() => handleCheckboxChange(location.id)}
                        />
                      </TableCell>
                      <TableCell className="erservices-table-cell">{location.title}</TableCell>
                      <TableCell className="erservices-table-cell">{location.address}</TableCell>
                      <TableCell className="erservices-table-cell">
                        <a href={getGoogleMapsUrl(location.latitude, location.longitude)} target="_blank" rel="noopener noreferrer">{location.latitude}</a>
                      </TableCell>
                      <TableCell className="erservices-table-cell">
                        <a href={getGoogleMapsUrl(location.latitude, location.longitude)} target="_blank" rel="noopener noreferrer">{location.longitude}</a>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No locations found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <footer className="manage-location-buttons">
          <button className="btn-primary cancel-button" onClick={() => drawerCtx.closeDrawer()}>
            Cancel
          </button>
          <button disabled={isSaveDisabled()} onClick={editEmergencyServiceLocation} className="btn-primary save-button">
            {isCreating ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Save"}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ManageLocationsDrawer;
