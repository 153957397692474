import React, { useContext, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import ModalContext from '../../../context/ModalContext.js'; 
import AuthContext from '../../../context/AuthContext.js';

const ChangeStatusModal = () => {
  
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  console.log(modalCtx.details);
  const [isUpdating, setIsUpdating] = useState(false);

  const { id, status } = modalCtx.details;
  const newStatus = status === "draft" ? "active" : "draft";
  
  const modalMessage = newStatus === "active" ? "Do you want to make this a live listing?" : "Would you like to deactivate this listing?";
  const modalButtonTxt = newStatus === "active" ? "ACTIVATE" : "DEACTIVATE";

  const handleStatusChange = () => {
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authCtx.token}`,
      },
      body: JSON.stringify({ status: newStatus }),
    })
    .then((response) => {
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
    .then(() => {
      modalCtx.setPageUpdate({ page: "ERServices", serviceId: id });
      modalCtx.closeModal();
    })
    .catch((error) => {
      console.error("Error updating status:", error.message);
    });
  };

  return (
    <>
      <div className="create-devotional-container">
        <div className="devotional-header">
          <h1>Change Status</h1>
        </div>
        <div className="body-container create-container">
          <Typography>{modalMessage}</Typography>
        </div>
        <div className="modal-footer">
          <Button style={{ color: "#555" }} onClick={() => modalCtx.closeModal()}>CANCEL</Button>
          <Button style={{ color: "#555" }} onClick={handleStatusChange}>{modalButtonTxt}</Button>
        </div>
      </div>
    </>
  );
};

export default ChangeStatusModal;
