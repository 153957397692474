import React, { useContext, useState, useEffect } from 'react';
import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';

import { CloseRounded } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';

const DeleteServiceModal = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const handleDelete = () => {
    const id = modalCtx.details;
    setIsDeleting(true);
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authCtx.token}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'success') {
        console.log('Service deleted successfully:', id);
        modalCtx.setPageUpdate({ page: 'ERServices', serviceId: id });
        modalCtx.closeModal();
      } else {
        console.error('Failed to delete service:', data.message);
      }
    })
    .catch((error) => {
      console.error('Error deleting service:', error);
    });
  };

  const closeHandle = () => {
    modalCtx.closeModal();
  }

  return (
    <>
      <div className="create-devotional-container">
        <div className='devotional-header'>
          <h1>Delete Service</h1>
          {/* <CloseRounded className='close' onClick={() => closeHandle()} /> */}
        </div>
        <div className=" body-container create-container">
          <span>Are you sure you want to delete this ER Service ?</span><br/>
          <strong>NOTE: This action cannot be undone</strong>
        </div>
        <div className="modal-footer">
          <Button className="btn-primary" onClick={() => closeHandle()}>Cancel</Button>
          <Button className="btn-primary-border" onClick={() => handleDelete()} disabled={isDeleting}>{ isDeleting ? <CircularProgress size={20} color="inherit" /> : "Confirm"}</Button>
        </div>
      </div>
    </>
  )

}

export default DeleteServiceModal;