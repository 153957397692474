import { useContext } from "react";
import { Button } from "@mui/material";
import AuthContext from "../../../context/AuthContext";
import ModalContext from "../../../context/ModalContext";

const DeleteTagModal = ({ modalDetails }) => {
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => modalCtx.closeModal();

  const deleteTag = () => {
    if (!modalDetails?.id) return;
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/tag/${modalDetails.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authCtx.token}`
      },
    })
      .then((res) => {
        if (!res.ok) throw new Error(`Error ${res.status}: ${res.statusText}`);
        return res.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log("Tag deleted successfully");
          modalCtx.closeModal();
        }
      })
      .catch(console.error);
  };
  

  return (
    <div className="create-devotional-container">
      <div className="devotional-header"><h1>Delete Tag</h1></div>
      <div className="body-container create-container">
        <span>Are you sure you want to delete this Tag?</span>
      </div>
      <div className="footer-devotional">
        <Button style={{ color: "#555" }} onClick={handleCloseModal}>No</Button>
        <Button style={{ color: "#555" }} onClick={deleteTag}>Yes</Button>
      </div>
    </div>
  );
};

export default DeleteTagModal;
