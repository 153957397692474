//react
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';



//mui
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Delete from "@mui/icons-material/Delete";
import { CheckCircleOutline } from '@mui/icons-material';
import { VariableSizeList } from "react-window";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, InputAdornment, Snackbar, SnackbarContent, Switch, FormGroup, FormControlLabel, CircularProgress, Tabs, Tab, RadioGroup, FormLabel, Radio, Checkbox, Autocomplete, createFilterOptions, Chip } from "@mui/material";

// import {
//   Map,
//   useApiIsLoaded,
//   useMapsLibrary,
//   AdvancedMarker,
//   Pin
// } from "@vis.gl/react-google-maps";

//context
import AuthContext from "../../../context/AuthContext";
import DrawerContext from "../../../context/DrawerContext";
import ModalContext from "../../../context/ModalContext";
import { generateTempLogo } from '../../../Pages/ERServices/ERServices.jsx';

//component
import TextInput from "../../../common/TextInput";
import { set } from "firebase/database";
const filter = createFilterOptions();

// const DefaultLocation = {
//   lat: 37.7749,
//   lng: -122.4194,
// };

const CreateEmergencyServiceDrawer = ({drawerDetails}) => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const { comapniesNameData, type } =  drawerDetails;
  const Details = comapniesNameData || [];

  const [serviceType, setServiceType] = useState("local");
  const [countryValue, setCountryValue] = useState(null);
  const [erType, setERType] = useState("Local");
  const [subRegionalType, setSubRegionalType] = useState("");
  
  
    const [categoryValue, setCategoryValue] = useState("");

  const [checkboxState, setCheckBoxState] = React.useState({
    phoneCheckbox: true,
    navigateCheckbox: false,
  });
  const {  phoneCheckbox, navigateCheckbox } = checkboxState;

  const [isCreating, setIsCreating] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [companyValue, setCompanyValue] = React.useState(null);
  const [erServiceTitleValue, setERServiceTitleValue] = React.useState(null);
  const [activeTab, setActiveTab] = useState("details"); 

  const [open, setOpen] = useState(false);
  const [snackbarContentText, setSnackbarContentText] = useState("");

  const [keywords, setKeywords] = useState();
  const [originalCompaniesList, setOriginalCompaniesList] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isDetailsValid, setIsDetailsValid] = useState(false);
  const [state, setState] = useState("");
  const [createEmergencyServiceObj, setEmergencyServiceObj] = useState({
    title: "",
    company: "",
    priority: "",
    phone: "",
    description: "",
    keywords: "no",
    locations: [],
  });

  const [createdServiceId, setCreatedServiceId] = useState(null);
  const companyId = drawerDetails?.companyId;
  const [locations, setLocations] = useState([]);
  const [isLocationAdded, setIsLocationAdded] = useState(false);
  const companyid = companyId?.drawerDetails?.companyDetails?.id;
  const [selectedPosition, setSelectedPosition] = useState({ lat: 28.7041, lng: 77.1025 });
  const [newLocation, setNewLocation] = useState({
    title: "",
    address: "",
    phone: "",
    email: "",
    country: "",
    latitude: "",
    longitude: ""
  })
  const [errorField, setErrorField] = useState({
    title: "",
    address: "",
    phone: "",
    email: "",
  })

  const emergencyContacts = [
    { Category: 'National Emergency Number', title: 'NATIONAL EMERGENCY HOTLINE', Priority: 1, type: 'police' },
    { Category: 'Ambulance Service (Public)', title: 'PUBLIC AMBULANCE', Priority: 5, type: 'medical' },
    { Category: 'Ambulance Service (Private)', title: 'PRIVATE AMBULANCE (PAID)', Priority: 6, type: 'medical' },
    { Category: 'Animal Hospital (or Veterinarian)', title: 'VETERINARIAN', Priority: 19, type: 'pests' },
    { Category: 'Air Ambulance', title: 'AIR AMBULANCE', Priority: 7, type: 'medical' },
    { Category: 'Disaster Management', title: 'DISASTER MANAGEMENT', Priority: 15, type: 'fire' },
    { Category: 'Fire Brigade', title: 'FIRE FIGHTERS', Priority: 4, type: 'fire' },
    { Category: 'Helpline (Childline)', title: 'CHILDREN’S HELPLINE', Priority: 20, type: 'police' },
    { Category: 'Helpline (Rapeline)', title: 'RAPE ASSISTANCE HELPLINE', Priority: 21, type: 'police' },
    { Category: 'Helpline (Depression & Anxiety)', title: 'DEPRESSION & ANXIETY HELPLINE', Priority: 22, type: 'medical' },
    { Category: 'Helpline (Suicide Prevention)', title: 'SUICIDE PREVENTION HELPLINE', Priority: 23, type: 'medical' },
    { Category: 'Helpline (Poison)', title: 'POISON HELPLINE', Priority: 24, type: 'medical' },
    { Category: 'Hospital (Public)', title: 'PUBLIC HOSPITAL', Priority: 8, type: 'medical' },
    { Category: 'Hospital (Private)', title: 'PRIVATE HOSPITAL (PAID)', Priority: 9, type: 'medical' },
    { Category: 'Hospital (Military)', title: 'MILITARY HOSPITAL (PAID)', Priority: 10, type: 'medical' },
    { Category: 'Humanitarian Organization', title: 'HUMANITARIAN RELIEF', Priority: 33, type: 'shelters' },
    { Category: 'Municipal Emergency Number', title: 'MUNICIPAL EMERGENCIES', Priority: 34, type: 'fire' },
    { Category: 'Neighborhood Watch', title: 'NEIGHBORHOOD WATCH', Priority: 28, type: 'police' },
    { Category: 'Pest Control (Baboon Control)', title: 'BABOON CONTROL', Priority: 25, type: 'pests' },
    { Category: 'Pest Control (Beekeeper)', title: 'BEEKEEPER', Priority: 26, type: 'pests' },
    { Category: 'Pest Control (Snake Handler)', title: 'SNAKE HANDLER', Priority: 27, type: 'pests' },
    { Category: 'Police Service', title: 'POLICE', Priority: 2, type: 'police' },
    { Category: 'Rescue (Mountain)', title: 'MOUNTAIN RESCUE', Priority: 12, type: 'rescue' },
    { Category: 'Rescue (Wilderness / Offroad)', title: 'OFFROAD & WILDERNESS RESCUE', Priority: 13, type: 'rescue' },
    { Category: 'Rescue (Sea)', title: 'SEA RESCUE', Priority: 14, type: 'rescue' },
    { Category: 'Rescue (Lifesavers)', title: 'LIFESAVERS', Priority: 15, type: 'rescue' },
    { Category: 'Shelter (Safehouse)', title: 'SAFEHOUSE', Priority: 17, type: 'shelters' },
    { Category: 'Shelter (Night Shelter)', title: 'NIGHT SHELTER', Priority: 18, type: 'shelters' },
    { Category: 'Tipline (Crime)', title: 'REPORT CRIME', Priority: 29, type: 'police' },
    { Category: 'Tipline (Fraud)', title: 'REPORT FRAUD', Priority: 30, type: 'police' },
    { Category: 'Tipline (Human Trafficking)', title: 'REPORT HUMAN TRAFFICKING', Priority: 31, type: 'police' },
    { Category: 'Tipline (Shark Sightings)', title: 'REPORT SHARK SIGHTINGS', Priority: 32, type: 'pests' },
    { Category: 'Traffic Department', title: 'TRAFFIC DEPARTMENT', Priority: 3, type: 'police' },
    { Category: 'Veterinarian (or Animal Hospital)', title: 'VETERINARIAN', Priority: 19, type: 'pests' },
    { Category: 'Volunteer Firefighters', title: 'VOLUNTEER FIRE FIGHTERS', Priority: 11, type: 'fire' },
  ];

  useEffect(() => {
    const isValid =
      !!companyValue &&
      !!erServiceTitleValue &&
      !!createEmergencyServiceObj.phone &&
      !!createEmergencyServiceObj.description &&
      Array.isArray(keywords) &&
      keywords.length > 0 &&
      (serviceType === "local" || (!!state && serviceType === "regional"));
  
    setIsDetailsValid(isValid);
  }, [ companyValue, erServiceTitleValue, createEmergencyServiceObj.phone, createEmergencyServiceObj.description, keywords, serviceType, state ]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    getAllCompanies();
  },[])

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (!checked && phoneCheckbox && navigateCheckbox) {
      setCheckBoxState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (checked) {
      setCheckBoxState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (!checked && !phoneCheckbox && !navigateCheckbox) {
      return;
    }
  };

  const getAllCompanies = () => {
    const companiesList = Details.map(company => ({
      id: company.id,
      title: company.companyDetails.company,
      image: company.logo
    }));
    setCompaniesData(companiesList);
    setOriginalCompaniesList(Details);
  }

  const createEmergencyService = () => {
    
    setIsCreating(true);
    const emergencyService = emergencyContacts.find((contact)=> contact.title === erServiceTitleValue?.title);
    // if (serviceType === "regional") {
    //   payload.state = state;
    // }

    if (!emergencyService) {
      console.error("Emergency Service not found!");
      setIsCreating(false);
      return;
    }
    
    const payload = {
      erType: erType || "local",
      priority: emergencyService.Priority,
      companyId: companyValue?.id,
      emergencyService: emergencyService.type,
      title: emergencyService.title,
      description: createEmergencyServiceObj.description,
      keywords: keywords ? keywords.join("|") : "",
      phone: createEmergencyServiceObj.phone,
      phoneBtn: phoneCheckbox ? "yes" : "no",
      navigateBtn: navigateCheckbox ? "yes" : "no",
      regionalType: categoryValue
    }
    
    console.log(payload);
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify(payload)
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {
        console.log("Created Emergency Service:", data);
        setCreatedServiceId(data?.data?.id);
        drawerCtx.setPageUpdate({page: "ERServices"});
        drawerCtx.closeDrawer();
        setIsCreating(false);
      }
    }).catch(err => {
      console.error(err)
      setIsCreating(false);
    });
  };

  

  // const getAllCountries= () => {
  //   fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/countries`, {
  //     method: 'GET',
  //     headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
  //   })
  //   .then(response => {
  //     return response.json();
  //   }).then(data => {
  //     if (data.status === 'success') {
  //       setCountries(
  //         data.data.map((country) => ({
  //           ...country,
  //           regions: country.regions || [],
  //         }))
  //       );
  //     }
  //   }).catch(err => {
  //     console.error(err)
  //   });
  // }
  
  

  

  // useEffect(() => {
  //   setRows(
  //     locations.map((location) => ({
  //       id: location.id,
  //       title: location.title,
  //       address: location.address,
  //       longitude: location.longitude,
  //       latitude: location.latitude,
  //     }))
  //   );
  // }, [locations]);  

  const isCreateDisabled = () => {
    const isCommonFieldsValid =
      !!companyValue &&
      !!erServiceTitleValue &&
      !!createEmergencyServiceObj.phone &&
      !!createEmergencyServiceObj.description &&
      Array.isArray(keywords) &&
      keywords.length > 0;
  
    const isRegionalValid = serviceType === "regional" ? !!countryValue && (categoryValue === "national" || !!state) : true;
  
    return !(isCommonFieldsValid && isRegionalValid && !isCreating);
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header><h1 data-aos="fade-right" data-aos-delay="500">Create Emergency Services</h1></header>
        <section>
          <div className="form-container">
            <Autocomplete className="full-width-column" value={companyValue || null}
              onChange={(event, newValue) => {
                console.log(newValue);
                if (newValue) {
                  setCompanyValue(newValue || null);
                } else {
                  setCompanyValue(null);
                }
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                // Suggest the creation of a new value
                return options.filter((option) =>
                  option.title.toLowerCase().includes(inputValue.toLowerCase())
                );
              }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="company-list"
                options={companiesData.filter((company) => company.title)}
                getOptionLabel={(option) => option?.title || "Unknown"}
                renderOption={(props, option) => (
                  <li {...props} key={option.id || option.title} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {option.image ? (
                      <img src={option.image} alt="Company Logo" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                    ) : (
                      generateTempLogo(option.title)
                    )}
                    <span>{option.title}</span>
                  </li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField className="full-width-column" fullWidth {...params} label="Company" />
                )}
                InputProps={{
                  style: {
                    padding: "0",      
                    height: "56px",       
                    display: "flex",
                    alignItems: "center",  
                    boxSizing: "border-box", 
                  },
                }}
                inputProps={{
                  style: {
                    padding: "0 16px",  
                    height: "100%",  
                    lineHeight: "1.5", 
                  },
                }}
                style={{
                  marginBottom: "16px",      
                }}
                InputLabelProps={{
                  style: {
                    transform: "translate(0, 10px) scale(1)", 
                  },
                }}
              />
            <Autocomplete
              className="full-width-column"
              value={erServiceTitleValue}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setERServiceTitleValue({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setERServiceTitleValue({
                    title: newValue.inputValue,
                  });
                } else {
                  setERServiceTitleValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="emergency-service-list"
              options={emergencyContacts}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option ) => (
                <li
                  {...props}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                    alignItems: 'center',
                    borderBottom: '1px solid #eee',
                    backgroundColor: props['aria-selected'] ? '#f0f0f0' : 'white',
                    cursor: 'pointer',
                  }}
                >
                  <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>{option.title}</span>
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                      color: '#666',
                      backgroundColor: '#e0e0e0',
                      padding: '2px 8px',
                      borderRadius: '12px',
                    }}
                  >
                    Priority: {option.Priority}
                  </span>
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField className="full-width-column" fullWidth {...params} label="Emergency Service" />
              )}
              InputProps={{
                style: {
                  padding: "0",             
                  height: "56px",          
                  display: "flex",
                  alignItems: "center",      
                  boxSizing: "border-box",   
                },
              }}
              inputProps={{
                style: {
                  padding: "0 16px",    
                  height: "100%",          
                  lineHeight: "1.5",    
                },
              }}
              style={{
                marginBottom: "16px",    
              }}
              InputLabelProps={{
                style: {
                  transform: "translate(0, 10px) scale(1)",
                },
              }}
            />
            <div className="" style={{ width: "100%", display: "flex" }}>
              <TextField
                fullWidth
                name="phone"
                label="Default Phone Number"
                value={createEmergencyServiceObj?.phone}
                onChange={(e) =>
                  setEmergencyServiceObj((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
                InputProps={{
                  style: {
                    padding: "0",        
                    height: "56px",   
                    display: "flex",    
                    alignItems: "center",   
                    boxSizing: "border-box", 
                  },
                }}
                inputProps={{
                  style: {
                    padding: "0 16px",        
                    height: "100%",            
                    lineHeight: "1.5",  
                  },
                }}
                style={{
                  marginBottom: "16px",       
                }}
              />
            </div>
            <TextField
              name="description"
              label="Description"
              value={createEmergencyServiceObj?.description}
              onChange={(e) =>
                setEmergencyServiceObj((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
              InputProps={{
                style: {
                  padding: "0",             
                  height: "56px",            
                  display: "flex",          
                  alignItems: "center",     
                  boxSizing: "border-box",  
                },
              }}
              inputProps={{
                style: {
                  padding: "0 16px",        
                  height: "100%",          
                  lineHeight: "1.5",      
                },
              }}
              style={{
                marginBottom: "16px",      
              }}
            />
            <Autocomplete
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              multiple
              id="keywords-list"
              options={[]}
              freeSolo
              value={keywords || []}
              onChange={(event, newValue) => {
                if (newValue) {
                  setKeywords(Array.isArray(newValue) ? [...newValue] : []);
                } else {
                  setKeywords([]);
                }
              }}
              renderTags={(value, getTagProps) =>
                (keywords || []).map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField className="full-width-column" fullWidth {...params} label="Keywords" />
              )}
            />     
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <FormLabel component="legend">Emergency Service Type</FormLabel>
                <RadioGroup row value={erType} onChange={(e) => setERType(e.target.value)} name="emergency-service-type">
                  <FormControlLabel value="Local" control={<Radio />} label="Local" />
                  <FormControlLabel value="Regional" control={<Radio />} label="Regional" />
                </RadioGroup>
            </div>
            {erType === "Local" && (
              <div className="flex-drawer">
                <FormControlLabel control={ <Checkbox checked={phoneCheckbox} onChange={handleCheckboxChange} name="phoneCheckbox" /> } label="Phone Button Visible" />
                <FormControlLabel control={ <Checkbox checked={navigateCheckbox} onChange={handleCheckboxChange} name="navigateCheckbox" /> } label="Navigate Button Visible" />
              </div>
            )}
            {erType === "Regional" && (
              <div style={{ marginTop: "1rem" }}>
                <FormLabel component="legend">Regional Type</FormLabel>
                <RadioGroup row value={subRegionalType} onChange={(e) => setSubRegionalType(e.target.value)} name="regional-type">
                  <FormControlLabel value="National" control={<Radio />} label="National" />
                  <FormControlLabel value="Regional" control={<Radio />} label="Regional" />
                </RadioGroup>
              </div>
            )}
            {/* <footer className="manage-location-buttons"> */}
            
          </div>
        </section>
        <footer>
          <button className="cancel-btn" onClick={(e) => drawerCtx.closeDrawer()}>CANCEL</button>
          <button disabled={isCreateDisabled()} onClick={(e) => createEmergencyService()}> {isCreating ? <CircularProgress size={16} style={{ color: "#fff" }} /> : ""} CREATE</button>
        </footer>
      </div>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <SnackbarContent style={{ backgroundColor: 'green', borderRadius: '10px', color: 'white', padding: '12px 20px', display: 'flex', alignItems: 'center', }}
            message={
              <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutline style={{ marginRight: '8px', fontSize: '20px' }} />
                {snackbarContentText}
              </span>
            }
          />
        </Snackbar>
    </div>
  );
};

export default CreateEmergencyServiceDrawer;