import React, { useState, useContext } from "react";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import DrawerContext from "../../../context/DrawerContext";
import "./TopicsDrawer.scss";

const AddTopicsDrawer = () => {
  const drawerCtx = useContext(DrawerContext);
  const isOpen = drawerCtx.details?.type === "add-topic";

  const [topicName, setTopicName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const isCreateDisabled = !topicName.trim();

  if (!isOpen) return null;

  const handleSave = () => {
    if (isCreateDisabled) return;
    setIsCreating(true);

    const payload = { title: topicName };

    console.log("Saving Topic:", payload);

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/topic`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((errorMessage) => {
            throw new Error(`Error ${res.status}: ${errorMessage}`);
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log("Topic added successfully:", data);
          drawerCtx.closeDrawer();
        }
      })
      .catch((error) => console.error("Topic creation failed:", error))
      .finally(() => setIsCreating(false));
  };

  return (
    <div className={`topic-drawer ${isOpen ? "open" : ""}`}>
      <div className="drawer-header">
        <Typography variant="h6">Add Topic</Typography>
      </div>
      <div className="drawer-content">
        <TextField
          className="full-width-column"
          fullWidth
          label="Topic Name"
          value={topicName}
          onChange={(e) => setTopicName(e.target.value)}
        />
      </div>
      <div className="drawer-footer">
        <Button className="btn-primary cancel-button" onClick={drawerCtx.closeDrawer}>
          Cancel
        </Button>
        <Button className="btn-primary save-button" onClick={handleSave} disabled={isCreateDisabled}>
          {isCreating ? <CircularProgress size={24} style={{ color: "white" }} /> : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default AddTopicsDrawer;
