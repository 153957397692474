//react
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

//mui
import { Button, TextField, Typography,Switch, FormGroup, FormControlLabel, CircularProgress, Autocomplete, Chip, Checkbox, createFilterOptions } from '@mui/material';

//context
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';

//component
import TextInput from '../../../common/TextInput'
const filter = createFilterOptions();



const AddCompanyDrawer = ({drawerDetails}) => {

  // console.log(drawerDetails, "this is our drawer details");
  
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [checkboxState, setCheckBoxState] = React.useState({
    phoneCheckbox: false,
    navigateCheckbox: false,
  });
  const { phoneCheckbox, navigateCheckbox } = checkboxState;
  const [isCreating, setIsCreating] = useState(false);
  const [allowLocation, setAllowLocation] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesName, setCompaniesName] = useState([]);
  const [companyValue, setCompanyValue] = React.useState(null);
  const [erServiceTitleValue, setERServiceTitleValue] = React.useState(null);
  const [value, setValue] = useState("details");
  const [keywords, setKeywords] = useState();
  const [locationValue, setLocationValues] = useState(null);
  const [originalCompaniesList, setOriginalCompaniesList] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryValue, setCountryValue] = useState(null);
  const [erRadioValue, setERRadioValue] = React.useState('');
  const [touchedFields, setTouchedFields] = useState({});
  const [errors, setErrors] = useState({});
  const [createCompanyObj, setCreateCompanyObj] = useState({
    companyName: "",
    slug: "",
    contactPerson: "",
    companyWebsite: "",
    email: "",
    mobile: "",
    description: "",
    address: "",
    colour: "",
    isGlobal: false,
    keywords: "",
    userScope: "",
    message: "",
    operationCountries: [],
  });
  
  const getAllCountries = () => {
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "success") {
        setCountries([{ name: "Globally" }, ...data?.data]); // Add "Globally" at the top
      }
    })
    .catch((err) => {
      console.error("Error fetching countries:", err);
    });
  };
  
  useEffect(() => {
    getAllCountries();
  }, []);

  const handleBlur = (field) => {
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
    // Only validate the specific field that is blurred
    handleFieldValidation(field);
  };
  
  const handleFieldValidation = (field) => {
    const errors = {};
    const touched = {};
  
    // Validate based on which field is being validated
    if (field === "companyName") {
      if (!createCompanyObj.companyName.trim()) {
        errors.companyName = "Company Name cannot be empty.";
      }
    } else if (field === "slug") {
      if (!createCompanyObj.slug.trim()) {
        errors.slug = "Slug cannot be empty.";
      } else if (!/^[a-z0-9-]+$/.test(createCompanyObj.slug.trim())) {
        errors.slug = "Slug can only contain lowercase letters, numbers, and hyphens.";
      }
    } else if (field === "contactPerson") {
      if (!createCompanyObj.contactPerson.trim()) {
        errors.contactPerson = "Contact Person cannot be empty.";
      } else if (!/^[a-zA-Z\s]+$/.test(createCompanyObj.contactPerson.trim())) {
        errors.contactPerson = "Contact Person can only contain alphabets and spaces.";
      }
    } else if (field === "email") {
      if (!createCompanyObj.email.trim() || !/\S+@\S+\.\S+/.test(createCompanyObj.email)) {
        errors.email = "Enter a valid email address.";
      }
    } else if (field === "mobile") {
      if (!createCompanyObj.mobile.trim() || createCompanyObj.mobile.trim().length !== 10) {
        errors.mobile = "Mobile Number must be 10 digits.";
      }
    } else if (field === "description") {
      if (!createCompanyObj?.description?.trim() || createCompanyObj?.description?.trim().length < 30) {
        errors.description = !createCompanyObj?.description?.trim() ? "Description cannot be empty." : "Description must be at least 30 characters.";
      }
    } else if (field === "country") {
      if (!countryValue?.name?.trim()) {
        errors.country = "Please select or enter a country.";
      }
    } else if (field === "keywords") {
      if ((keywords || []).length === 0) {
        errors.keywords = "At least one keyword is required.";
      }
    } else if (field === "colour") {
      if (createCompanyObj?.colour?.trim() && 
          !/^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/.test(createCompanyObj.colour.trim())) {
        errors.colour = "Enter a valid HEX colour code (e.g., #ffffff or #fff).";
      }
    } else {
      console.log("No need to validate");
    }
  
    touched[field] = true;
    setErrors(errors); // Set the updated errors state
    setTouchedFields(touched); 
  };

  const handleFullValidation = () => {
    const errors = {};
    const touched = {};
  
    // Validate Company Name
    if (!createCompanyObj.companyName.trim()) {
      errors.companyName = "Company Name cannot be empty.";
      touched.companyName = true;
      console.log("Validation failed for companyName: Company Name cannot be empty.");
    }
  
    // Validate Slug
    if (!createCompanyObj.slug.trim()) {
      errors.slug = "Slug cannot be empty.";
      touched.slug = true;
      console.log("Validation failed for slug: Slug cannot be empty.");
    } else if (!/^[a-z0-9-]+$/.test(createCompanyObj.slug.trim())) {
      errors.slug = "Slug can only contain lowercase letters, numbers, and hyphens.";
      touched.slug = true;
      console.log("Validation failed for slug: Slug can only contain lowercase letters, numbers, and hyphens.");
    }
  
    // Validate Contact Person
    if (!createCompanyObj.contactPerson.trim()) {
      errors.contactPerson = "Contact Person cannot be empty.";
      touched.contactPerson = true;
      console.log("Validation failed for contactPerson: Contact Person cannot be empty.");
    } else if (!/^[a-zA-Z\s]+$/.test(createCompanyObj.contactPerson.trim())) {
      errors.contactPerson = "Contact Person can only contain alphabets and spaces.";
      touched.contactPerson = true;
      console.log("Validation failed for contactPerson: Contact Person can only contain alphabets and spaces.");
    }
  
    // Validate Email
    if (!createCompanyObj.email.trim() || !/\S+@\S+\.\S+/.test(createCompanyObj.email)) {
      errors.email = "Enter a valid email address.";
      touched.email = true;
      console.log("Validation failed for email: Enter a valid email address.");
    }
  
    // Validate Mobile
    if (!createCompanyObj.mobile.trim() || createCompanyObj.mobile.trim().length !== 10) {
      errors.mobile = "Mobile Number must be 10 digits.";
      touched.mobile = true;
      console.log("Validation failed for mobile: Mobile Number must be 10 digits.");
    }
  
    // Validate Description
    if (!createCompanyObj?.description?.trim()) {
      errors.description = "Description cannot be empty.";
      touched.description = true;
      console.log("Validation failed for description: Description cannot be empty.");
    } else if (createCompanyObj?.description?.trim().length < 30) {
      errors.description = "Description must be at least 30 characters.";
      touched.description = true;
      console.log("Validation failed for description: Description must be at least 30 characters.");
    }

    // Validate Country
    if (createCompanyObj.isGlobal || createCompanyObj.operationCountries.length > 0) {
      delete errors.country;
      delete touched.country; 
    } else {
      errors.country = "Please select or enter a country.";
      touched.country = true;
      console.log("Validation failed for country: Please select or enter a country.");
    }

    // Validate Keywords
    if ((keywords || []).length === 0) {
      errors.keywords = "At least one keyword is required.";
      touched.keywords = true;
      console.log("Validation failed for keywords: At least one keyword is required.");
    }

    //Validate Colour
    if (
      createCompanyObj?.colour?.trim() && 
      !/^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/.test(createCompanyObj.colour.trim())
    ) {
      errors.colour = "Enter a valid HEX colour code (e.g., #ffffff or #fff).";
      touched.colour = true;
      console.log("Validation failed for colour: Enter a valid HEX colour code.");
    }
  
    // Set errors and touched states for all fields
    setErrors(errors);
    setTouchedFields(touched);
  
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const isCreateDisabled = () => {
    return handleFullValidation();
  };

  const createCompany = () => {

    const isValid = handleFullValidation();
    if (!isValid) {
      console.log("Validation failed");
      return;
    }
    setIsCreating(true);
    console.log(createCompanyObj);    
  
    const {
      companyName,
      slug,
      contactPerson,
      email,
      mobile,
      description,
      address,
      colour,
      isGlobal,
      keywords,
      userScope,
      message,
    } = createCompanyObj;
  
    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/company`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify(createCompanyObj),
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          console.log("Company created successfully");
          setIsCreating(false);
          drawerCtx.setPageUpdate({page: "Companies"});
          drawerCtx.closeDrawer();
        } else {
          console.error("Failed to create company:", data.message);
          setIsCreating(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsCreating(false);
      });
    } catch (error) {
      console.error("Unexpected error:", error);
      setIsCreating(false);
    }
  };

  const getAllCompanies = () => {    

    if(drawerDetails) {
      const companiesList = drawerDetails.map(company => ({
        id: company.id,
        title: company.companyDetails.company,
        image: company.logo
      }));  
      setCompaniesData(companiesList);
      setOriginalCompaniesList(drawerDetails);
    } else {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          // console.log(data?.data, "this is compnay the data");
          const companiesList = data?.data.map(company => ({
            id: company.id,
            title: company.companyDetails.company
          }));

          setCompaniesData(companiesList);
          setOriginalCompaniesList(data?.data);

        }
      }).catch(err => {
        console.error(err)
        // setUpdatingStatus(false)
      });
    }

  }

  useEffect(()=>{
    getAllCompanies();
  },[])

  useEffect(()=>{
    const locationData = originalCompaniesList?.filter((company)=> company.companyDetails.company === companyValue.title)[0].locations;
    // console.log(locationData, "this is our location data");
    setLocationValues(locationData);
  },[companyValue])

  useEffect(()=>{
    let  columns = [
      { field: 'title', headerName: 'Title', width: 150 },
      { field: 'address', headerName: 'Address', flex: 1 },
      { field: 'longitude', headerName: 'Longitude', width: 150 },
      { field: 'latitude', headerName: 'Latitude', width: 150 },
    ];
    
    let rows = locationValue?.map((location, index) => ({
      id: location.id,
      title: location.title,
      address: location.address,
      longitude: location.longitude,
      latitude:location.latitude,
    }));

    setColumns(columns);
    setRows(rows);
  },[locationValue])

  const generateSlug = (companyName) => {
    return companyName
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '') // Remove special characters except hyphens
      .replace(/\s+/g, '-')     // Replace spaces with hyphens
      .replace(/-+/g, '-');     // Replace multiple hyphens with single hyphen
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header><h1 data-aos="fade-right" data-aos-delay="500">Create Company</h1></header>
        <section>
          <div className="form-container">

            {/* Company Name */}
            <TextField
              error={touchedFields.companyName && !!errors.companyName}
              id="outlined-company-name"
              placeholder="Company Name"
              helperText={ touchedFields.companyName && errors.companyName ? errors.companyName : " " }
              fullWidth
              name="companyName"
              label="Company Name"
              value={createCompanyObj.companyName}
              onChange={(e) => {
                const newCompanyName = e.target.value;
                const newSlug = generateSlug(newCompanyName);
                setCreateCompanyObj((prev) => ({
                  ...prev,
                  companyName: newCompanyName,
                  slug: newSlug,
                }));
              }}
              onBlur={() => handleBlur("companyName")}
            />

            {/* Slug Field */}
            <TextField
              error={
                touchedFields.slug &&
                (!createCompanyObj.slug.trim() || !/^[a-z0-9-]+$/.test(createCompanyObj.slug.trim()))
              }
              id="outlined-slug"
              placeholder="Slug"
              helperText={
                touchedFields.slug
                  ? !createCompanyObj.slug.trim()
                    ? "Slug cannot be empty."
                    : !/^[a-z0-9-]+$/.test(createCompanyObj.slug.trim())
                    ? "Slug can only contain lowercase letters, numbers, and hyphens."
                    : " "
                  : " "
              }
              fullWidth
              name="slug"
              label="Slug"
              value={createCompanyObj.slug}
              onChange={(e) =>
                setCreateCompanyObj((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
              onBlur={() => handleBlur("slug")}
            />

            {/* Contact Person */}
            <TextField
              error={
                touchedFields.contactPerson &&
                (!createCompanyObj.contactPerson.trim() ||
                  !/^[a-zA-Z\s]+$/.test(createCompanyObj.contactPerson.trim()))
              }
              id="outlined-contact-person"
              placeholder="Contact Person"
              helperText={
                touchedFields.contactPerson
                  ? !createCompanyObj.contactPerson.trim()
                    ? "Contact Person cannot be empty."
                    : !/^[a-zA-Z\s]+$/.test(createCompanyObj.contactPerson.trim())
                    ? "Contact Person can only contain alphabets and spaces."
                    : " "
                  : " "
              }
              fullWidth
              name="contactPerson"
              label="Contact Person"
              value={createCompanyObj.contactPerson}
              onChange={(e) =>
                setCreateCompanyObj((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
              onBlur={() => handleBlur("contactPerson")}
            />

            {/* Email */}
            <TextField
              error={
                touchedFields.email &&
                (!createCompanyObj?.email?.trim() ||
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(createCompanyObj?.email.trim()))
              }
              id="outlined-email"
              placeholder="Email Address"
              helperText={
                touchedFields.email
                  ? !createCompanyObj?.email?.trim()
                    ? "Email Address cannot be empty."
                    : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(createCompanyObj?.email.trim())
                    ? "Please enter a valid email address."
                    : " "
                  : " "
              }
              fullWidth
              name="email"
              label="Email Address"
              value={createCompanyObj?.email || ""}
              onChange={(e) =>
                setCreateCompanyObj((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
              onBlur={() => handleBlur("email")}
            />

            {/* Mobile Number */}
            <TextField
              error={
                touchedFields.mobile &&
                (!createCompanyObj?.mobile?.trim() ||
                  !/^\d{10}$/.test(createCompanyObj?.mobile.trim()))
              }
              id="outlined-mobile-number"
              placeholder="Mobile Number"
              helperText={
                touchedFields.mobile
                  ? !createCompanyObj?.mobile?.trim()
                    ? "Mobile Number cannot be empty."
                    : !/^\d{10}$/.test(createCompanyObj?.mobile.trim())
                    ? "Mobile Number must be exactly 10 digits."
                    : " "
                  : " "
              }
              fullWidth
              name="mobile"
              label="Mobile Number"
              value={createCompanyObj?.mobile || ""}
              onChange={(e) =>
                setCreateCompanyObj((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
              onBlur={() => handleBlur("mobile")}
            />
          
            {/* Description */}
            <TextField
              error={
                touchedFields.description &&
                (!createCompanyObj?.description?.trim() ||
                createCompanyObj?.description.trim().length < 30)
              }
              helperText={
                touchedFields.description
                  ? !createCompanyObj?.description?.trim()
                    ? "Description cannot be empty."
                    : createCompanyObj?.description.trim().length < 30
                    ? "Description must be at least 30 characters."
                    : " "
                  : " "
              }
              name="description"
              label="Description"
              value={createCompanyObj?.description || ""}
              onChange={(e) =>
                setCreateCompanyObj((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
              onBlur={() => handleBlur("description")}
              fullWidth
              multiline
              rows={4}
              placeholder="Enter at least 30 characters..."
            />


            {/* Color  */}
            <div>
              {/* Note */}
              <Typography variant="body2" color="textSecondary" style={{ marginBottom: "8px" }}>
                NOTE: We recommend that you leave this field empty if you are unsure what to put here. 
                When the coupon is approved, the administration will make sure this value is correct.
              </Typography>

              {/* Colour Input Field */}
              <TextField
                fullWidth
                name="colour"
                label="Colour"
                placeholder="#ffffff"
                value={createCompanyObj?.colour || ""}
                onChange={(e) =>
                  setCreateCompanyObj((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
                onBlur={() => handleBlur("colour")}
                helperText={
                  touchedFields.colour && errors.colour
                    ? errors.colour
                    : " "
                }
                
                error={touchedFields.colour && !!errors.colour}
              />
            </div>
          
            {/* Country */}
            <div>
              {/* Note */}
              <Typography variant="body2" color="textSecondary" style={{ marginBottom: "8px" }}>
                NOTE: You can select "Globally" if this is a digital service that can be provided to any country.
              </Typography>

              {/* Country Selector */}
              <Autocomplete
                multiple={!createCompanyObj.isGlobal} 
                value={
                  createCompanyObj.isGlobal
                    ? "Globally"
                    : createCompanyObj.operationCountries
                }
                onChange={(event, newValue) => {

                  if (Array.isArray(newValue) && newValue.some((item) => item?.name === "Globally")) {
                    setCreateCompanyObj((prev) => ({
                      ...prev,
                      isGlobal: true,
                      operationCountries: [],
                    }));
                  } else if (Array.isArray(newValue)) {
              
                    setCreateCompanyObj((prev) => ({
                      ...prev,
                      isGlobal: false,
                      operationCountries: newValue.filter((item) =>
                        countries.some((country) => country.name === item.name)
                      ),
                    }));
                  } else {
                  
                    setCreateCompanyObj((prev) => ({
                      ...prev,
                      isGlobal: false,
                      operationCountries: [],
                    }));
                  }
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;

                  if (createCompanyObj.isGlobal) {
                    return [];
                  }            
                  const filteredOptions = options.filter(
                    (option) =>
                      option.name.toLowerCase().includes(inputValue.toLowerCase()) &&
                      option.name !== "Globally"
                  );

                  if (!createCompanyObj.isGlobal && inputValue === "") {
                    return [{ name: "Globally" }, ...filteredOptions];
                  }

                  return filteredOptions;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="country-list"
                options={[{ name: "Globally" }, ...countries]}
                getOptionLabel={(option) => (typeof option === "string" ? option : option.name || "")}
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                freeSolo={false} 
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={
                      !createCompanyObj.isGlobal &&
                      createCompanyObj.operationCountries.length === 0
                        ? "Countries of Operation"
                        : ""
                    } 
                    placeholder={
                      !createCompanyObj.isGlobal &&
                      createCompanyObj.operationCountries.length === 0
                        ? "Countries of Operation"
                        : ""
                    } 
                    error={
                      touchedFields.country &&
                      (!createCompanyObj.isGlobal &&
                        createCompanyObj.operationCountries.length === 0)
                    }
                    helperText={
                      touchedFields.country
                        ? !createCompanyObj.isGlobal &&
                          createCompanyObj.operationCountries.length === 0
                          ? "Please select at least one country."
                          : " "
                        : " "
                    }
                    onBlur={() => {
                      if (
                        !createCompanyObj.isGlobal &&
                        createCompanyObj.operationCountries.length === 0
                      ) {
                        handleBlur("country")
                      }
                    }}
                  />
                )}
              />
            </div>          

            <Autocomplete
              multiple
              id="keywords-list"
              options={[]} // Add predefined options if needed
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={createCompanyObj?.keywords || []} // Ensure keywords is always an array
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setKeywords([...(keywords || []), newValue]);
                  setCreateCompanyObj((prev) => ({
                    ...prev,
                    keywords: [...(keywords || []), newValue],
                  }))
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setKeywords([...(keywords || []), newValue.inputValue]);
                  setCreateCompanyObj((prev) => ({
                    ...prev,
                    keywords: [...(keywords || []), newValue.inputValue],
                  }))
                } else {
                  setKeywords([...newValue]);
                  setCreateCompanyObj((prev) => ({
                    ...prev,
                    keywords: [...newValue],
                  }))
                }
              }}
              renderTags={(value, getTagProps) =>
                (keywords || []).map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Keywords"
                  error={(keywords || []).length === 0 && touchedFields.keywords}
                  helperText={
                    (keywords || []).length === 0 && touchedFields.keywords
                      ? "At least one keyword is required."
                      : " "
                  }
                  onBlur={() => handleBlur("keywords")}
                />
              )}
            />

            {/* <Autocomplete
              className="full-width-column"
              value={companyValue}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setCompanyValue({
                    title: newValue,
                  });

                  

                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setCompanyValue({
                    title: newValue.inputValue,
                  });

                } else {
                  setCompanyValue(newValue);
                
                }

              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="company-list"
              options={companiesData}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li className="" {...props}><img className="company-logo-img" src={option.image} alt="Company Logo"/> {option.title}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField className="full-width-column" fullWidth {...params} label="Company" />
              )}
            /> */}
          
            {/* <div className="" style={{ width: "100%", display: "flex" }}>
              <TextField
                fullWidth
                name="priority"
                label="Priority"
                value={createEmergencyServiceObj?.priority}
                onChange={(e) =>
                  setEmergencyServiceObj((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
              <TextField
                fullWidth
                name="phone"
                label="Default Phone Number"
                value={createEmergencyServiceObj?.phone}
                onChange={(e) =>
                  setEmergencyServiceObj((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
            </div> */}
          
            {/* <div className="flex-drawer">
              <FormControlLabel
                control={
                  <Checkbox checked={phoneCheckbox} onChange={handleCheckboxChange} name="phoneCheckbox" />
                }
                label="Phone Button Visible"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={navigateCheckbox} onChange={handleCheckboxChange} name="navigateCheckbox" />
                }
                label="Navigate Button Visible"
              />
            </div> */}
        {/* <FormGroup>
        <FormControlLabel label="Video" control={<Switch />} onChange={e => handleVideoBoolean(e.target.checked)} />
      </FormGroup> */}
        {/* <TextField disabled={createBlogObj?.isVideo !== 'yes'} name="videoUrl" label="Video Url" value={createBlogObj?.videoUrl} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Video Url" /> */}
          </div>
        </section>
        <footer className="double-column-form column-full-width">
          <button className="cancel-btn" onClick={(e)=> drawerCtx.closeDrawer()}>CANCEL</button>
          {/* todo: make the button disabled until there's enough details */}
          <button disabled={!handleFullValidation} onClick={(e) => createCompany()}> {isCreating ? <CircularProgress size={16} style={{ color: "#fff" }} /> : ""} CREATE</button>
        </footer>
      </div>
    </div>
  )
}

export default AddCompanyDrawer;
