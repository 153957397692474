import React, { useEffect, useState, useContext } from "react";
import { 
  Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, IconButton, CircularProgress, TextField, InputAdornment, TablePagination 
} from "@mui/material";
import { Delete, Edit, Search, StarRounded } from "@mui/icons-material";
import ModalContext from "../../context/ModalContext.js";
import DrawerContext from "../../context/DrawerContext.js";
import AuthContext from "../../context/AuthContext.js";
import "../../Pages/Topics/Topics.scss";

const Topics = () => {
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updatingBlogStatus, setUpdatingBlogStatus] = useState(false);
  const [updatingBlogStatusId, setUpdatingBlogStatusId] = useState();

  useEffect(() => { getAllTopics(); }, []);
  useEffect(() => { getAllTopics(); }, [drawerCtx, modalCtx]);

  const getAllTopics = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/topics", { 
      method: "GET", 
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token } 
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") setTopics(data.data || []);
      setLoading(false);
    })
    .catch(() => {
      setTopics([]);
      setLoading(false);
    });
  };

  const updateStatusBlogs = (blogId, status) => {
    setUpdatingBlogStatus(true);
    setUpdatingBlogStatusId(blogId);
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/topics/" + blogId + "/updateFeaturedBlog", {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({ status })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        getAllTopics();
        setUpdatingBlogStatus(false);
        setUpdatingBlogStatusId(null);
      }
    })
    .catch(() => setUpdatingBlogStatus(false));
  };

  const openDeleteTopicModal = (id) => {
    modalCtx.setDetails("delete-topic", { id });
    modalCtx.openModal();
  };

  const toggleDrawerHandler = (drawer, data = {}) => {
    drawerCtx.setDetails(drawer, { type: drawer, ...data }); 
    drawerCtx.openDrawer();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="topics-container">
      <div className="header-section">
        <span className="title">TOPICS</span>
        <div className="header-items-div">
          <TextField variant="outlined" size="small" placeholder="Search" value={searchInput} 
            onChange={(e) => setSearchInput(e.target.value)} 
            InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }} 
            className="search-bar" />
          <Button variant="contained" className="add-button" onClick={() => toggleDrawerHandler("add-topic", null)}>+ ADD TOPIC</Button>
        </div>
      </div>
      <section className="topics-table-container">
        <TableContainer component={Paper} className="topics-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="topics-head-cell">Topic Name</TableCell>
                <TableCell className="topics-head-cell star-column" align="center">Featured for Blogs</TableCell>
                <TableCell className="topics-head-cell" align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center" style={{ border: "0px" }}><CircularProgress size={48} style={{ color: "#e84118",border: "0px" }} /></TableCell>
                </TableRow>
              ) : topics.length > 0 ? (
                topics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((topic) => (
                  <TableRow key={topic.id} className="topics-table-row">
                    <TableCell className="topics-table-cell">{topic.title || "Unnamed Topic"}</TableCell>
                    <TableCell className="star-cell">
                      {updatingBlogStatus && updatingBlogStatusId === topic.id ? 
                        <CircularProgress size={20} /> : 
                        <StarRounded 
                          className={topic.featured_blogs === "yes" ? "star-active" : "star-inactive"} 
                          onClick={() => updateStatusBlogs(topic.id, topic.featured_blogs === "yes" ? "no" : "yes")} 
                        />
                      }
                    </TableCell>
                    <TableCell align="right">
                      <IconButton className="action-icon" size="small" onClick={() => toggleDrawerHandler("edit-topic", topic)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton className="action-icon" size="small" onClick={() => openDeleteTopicModal(topic.id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">No Topics Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      <TableContainer component={Paper} className="topics-pagination">
        <Table>
          <TableBody>
            <TableRow>
              <TablePagination 
                rowsPerPageOptions={[10, 25, 50]} 
                colSpan={3} 
                count={topics.length} 
                rowsPerPage={rowsPerPage} 
                page={page}
                onPageChange={handleChangePage} 
                onRowsPerPageChange={handleChangeRowsPerPage} 
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Topics;
